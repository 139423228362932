import { createTheme, ThemeOptions } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    accent1: string;
    accent2: string;
    ultraDarkGray: string;
    darkGray: string;
    lightGray: string;
    ultraLightGray: string;
    shadow: string;
    black: string;
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    accent1: string;
    accent2: string;
    ultraDarkGray: string;
    darkGray: string;
    lightGray: string;
    ultraLightGray: string;
    shadow: string;
    black: string;
  }

  interface PaletteColor {
    tint: PaletteColor['main'];
    shade: PaletteColor['main'];
    highlight: PaletteColor['main'];
  }

  interface SimplePaletteColorOptions {
    tint?: SimplePaletteColorOptions['main'];
    shade?: SimplePaletteColorOptions['main'];
    highlight?: SimplePaletteColorOptions['main'];
  }

  interface TypographyVariants {
    small: React.CSSProperties;
    strong: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
    strong?: React.CSSProperties;
  }
}

function calculateRemFromPixel(targetSize: number) {
  return targetSize / 16 + 'rem';
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#01579B', // primary
      light: '#3479AF', // primary-tint
      tint: '#3479AF',
      dark: '#014E8C', // primary-shade
      shade: '#014E8C',
      contrastText: '#ffffff', // primary-contrast
      highlight: '#EFF8FF',
    },
    secondary: {
      main: '#37474F',
      light: '#5E7986',
      tint: '#5E7986',
      dark: '#324047',
      shade: '#324047',
      contrastText: '#ffffff',
      highlight: '#EBEDED',
    },
    tertiary: {
      main: '#002A61',
      light: '#1A3F71',
      tint: '#1A3F71',
      dark: '#002657',
      shade: '#002657',
      contrastText: '#ffffff',
      highlight: '#CCD4DF',
    },
    success: {
      main: '#4CAF50',
      light: '#E8F5E9',
    },
    warning: {
      main: '#FFC107',
      light: '#FFF8E1',
    },
    error: {
      main: '#BA000D',
      light: '#FFEAEC',
    },
    info: {
      main: '#0075FF',
      light: '#CCE3FF',
      dark: '#DDECFF',
    },
    background: {
      default: '#ffffff',
      paper: '#eeeeee',
    },
    accent1: '#F57C00',
    accent2: '#FFE17C',
    ultraDarkGray: '#424242',
    darkGray: '#757575',
    shadow: '#D9D9D9',
    lightGray: '#EEEEEE',
    ultraLightGray: '#F6F6F6',
    black: '#212121',
  },
  // https://www.figma.com/file/iv56QSdcUfAkpdJLIcL9aI/ADS%2FM3-Figma-Tokens?node-id=421%3A263
  typography: {
    h1: {
      fontSize: calculateRemFromPixel(28),
      lineHeight: calculateRemFromPixel(32),
      fontWeight: '700',
    },
    h2: {
      fontSize: calculateRemFromPixel(24),
      lineHeight: calculateRemFromPixel(28),
      fontWeight: '700',
    },
    h3: {
      fontSize: calculateRemFromPixel(18),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '700',
    },
    h4: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '700',
    },
    h5: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '400',
    },
    h6: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(22),
      fontWeight: '400',
      fontStyle: 'italic',
    },
    subtitle1: {
      fontSize: calculateRemFromPixel(20),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '400',
    },
    subtitle2: {
      fontSize: calculateRemFromPixel(18),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '300',
    },
    body1: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '400',
    },
    body2: {
      fontSize: calculateRemFromPixel(14),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '400',
    },
    caption: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '400',
    },
    overline: {
      fontSize: calculateRemFromPixel(15),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '300',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(16),
      fontWeight: '700',
    },
    strong: {
      fontSize: calculateRemFromPixel(16),
      lineHeight: calculateRemFromPixel(24),
      fontWeight: '700',
    },
    small: {
      fontSize: calculateRemFromPixel(12),
      lineHeight: calculateRemFromPixel(20),
    },
  },
};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true;
    body2: true;
  }
}

const baseTheme = createTheme(themeOptions);

export default createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: '700',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: calculateRemFromPixel(14),
          },
        },
      },
    },
  },
  baseTheme
);
