import { useIsAuthenticated } from '@azure/msal-react';
import { Button, Stack, Typography } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '~/app/hooks';
import { loginRequested } from './authSlice';

export const LoginButton = () => {
  const dispatch = useAppDispatch();
  const handleLogin = () => dispatch(loginRequested());

  return (
    <>
      <Typography mb={1}>
        The Viagio Intranet is available to current associates. You'll need to log in to use the site.
      </Typography>
      <Button onClick={handleLogin} variant="contained" color="primary">
        Log in with your Viagio account
      </Button>
    </>
  );
};

type PossibleLocationState = {
  from?: { pathname?: string };
};

export const LoginScreen = () => {
  let location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  let from = (location.state as PossibleLocationState)?.from?.pathname || '/';

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return (
    <Stack alignItems="center" flexDirection="column">
      <LoginButton />
    </Stack>
  );
};
