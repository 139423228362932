import { useDispatch, useSelector } from 'react-redux';
import { selectSnacks, snackRemoved } from './snackBarSlice';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBarContainer = () => {
  const snacks = useSelector(selectSnacks);
  const dispatch = useDispatch();

  return (
    <>
      {snacks.map((snack) => (
        <Snackbar autoHideDuration={6000} key={snack.id} open={true} onClose={() => dispatch(snackRemoved(snack.id))}>
          <Alert severity={snack.severity}>{snack.message}</Alert>
        </Snackbar>
      ))}
    </>
  );
};
