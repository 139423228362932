import { createAction, createSelector, createSlice, nanoid } from '@reduxjs/toolkit';

export type Snack = {
  id: string;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
};

export const initialState = {
  snacks: [] as Snack[],
};

export type SnackbarState = typeof initialState;
export type SnackbarSlice = { snackbar: SnackbarState };

export type SnackAddedArgs = Omit<Snack, 'id'>;
export const snackAdded = createAction<SnackAddedArgs>('snackbar/snackAdded');
export const snackRemoved = createAction<string>('snackbar/snackRemoved');

export const selectSlice = (state: SnackbarSlice) => state.snackbar;
export const selectSnacks = createSelector(selectSlice, (slice) => slice.snacks);

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(snackAdded, (draft, { payload }) => {
      draft.snacks.push({ ...payload, id: nanoid() });
    });

    builder.addCase(snackRemoved, (draft, { payload }) => {
      draft.snacks = draft.snacks.filter((snack) => snack.id !== payload);
    });
  },
});
