import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { Api } from '~/app/api';
import { GetCurrentFeatureFlagsResponse } from '~/app/generated/api-client/v1';
import { AxiosError } from 'axios';

export const featureFlagData = createApi({
  reducerPath: 'featureFlags',
  baseQuery: fakeBaseQuery<AxiosError | Error>(),
  tagTypes: [],

  endpoints: (builder) => ({
    getFeatureFlags: builder.query<GetCurrentFeatureFlagsResponse, void>({
      queryFn: () => Api.config.featureFlags(),
      keepUnusedDataFor: 10,
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagData;
