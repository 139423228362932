import { Avatar, AvatarTypeMap } from '@mui/material';

// https://mui.com/components/avatars/#main-content
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export type UserAvatarProps = {
  user: { profilePictureUrl?: string | null; firstName: string; lastName: string };
} & AvatarTypeMap['props'];
export const UserAvatar = ({ user, ...avatarProps }: UserAvatarProps) => {
  const initials = user.firstName[0] + user.lastName[0];
  const props = {
    src: user.profilePictureUrl ? user.profilePictureUrl : undefined,
    ...(!user.profilePictureUrl && {
      sx: { ...avatarProps.sx, backgroundColor: stringToColor(initials) },
      children: initials,
    }),
  };

  return <Avatar {...avatarProps} {...props} alt={`${user.firstName} ${user.lastName}'s avatar`} />;
};
