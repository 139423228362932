import { useMemo } from 'react';
import { useGetFeatureFlagsQuery } from './featureFlagSlice';
import { FeatureFlagAccessorHook } from './types';

export const useViagioAcceleratorFeatureFlags: FeatureFlagAccessorHook = () => {
  const { data, isError, isSuccess } = useGetFeatureFlagsQuery(undefined, {
    pollingInterval: 60 * 1000 * 10,
    refetchOnReconnect: true,
  });
  const isLoaded = isError || isSuccess;

  const contextValue = useMemo(() => {
    const flags: Record<string, boolean> = data?.featureFlags ?? {};
    return {
      isLoaded,
      featureFlags: flags,
    };
  }, [isLoaded, data]);

  return contextValue;
};
