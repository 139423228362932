/* tslint:disable */
/* eslint-disable */
/**
 * ATG.Intranet.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError } from '../base';
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
import {
  CreateEstimateRequest,
  CreateTaskTemplateRequest,
  CreateTemplateGroupRequest,
  ErrorResponse,
  EstimateMetaDataSearchResponse,
  EstimateResponse,
  ImmutableEstimate,
  ModelUpdateIdentifier,
  TaskTemplateResponse,
  TemplateGroupResponse,
  ValidationErrorResponse,
} from '../model';

/**
 * GuesstimatorApi - axios parameter creator
 * @export
 */
export const GuesstimatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Creates a new estimate
     * @param {CreateEstimateRequest} [createEstimateRequest] The request body with information needed to create a new estimate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEstimate: async (
      createEstimateRequest?: CreateEstimateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/estimates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createEstimateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new task template
     * @param {CreateTaskTemplateRequest} [createTaskTemplateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTaskTemplate: async (
      createTaskTemplateRequest?: CreateTaskTemplateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/task-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTaskTemplateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new template group
     * @param {CreateTemplateGroupRequest} [createTemplateGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTemplateGroup: async (
      createTemplateGroupRequest?: CreateTemplateGroupRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/template-groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTemplateGroupRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a single estimate\'s data
     * @param {string} estimateKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimate: async (estimateKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'estimateKey' is not null or undefined
      assertParamExists('getEstimate', 'estimateKey', estimateKey);
      const localVarPath = `/api/v1/guesstimator/estimates/{estimateKey}`.replace(
        `{${'estimateKey'}}`,
        encodeURIComponent(String(estimateKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Metadata: Gets a list of all task templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/task-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Metadata: Gets a list of all template groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/template-groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Searches estimates and returns the common metadata for them
     * @param {string} [name]
     * @param {number} [skip] The number of chunks (or takes) to skip before returning the next chunk. Default is 0
     * @param {number} [take] The number of estimates to return in a chunk. Default is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEstimates: async (
      name?: string,
      skip?: number,
      take?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/guesstimator/estimates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates an estimate
     * @param {string} estimateKey The unique identifier for a specific estimate
     * @param {number} version The estimate related to a given version of an estimate
     * @param {ImmutableEstimate} [immutableEstimate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEstimate: async (
      estimateKey: string,
      version: number,
      immutableEstimate?: ImmutableEstimate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'estimateKey' is not null or undefined
      assertParamExists('updateEstimate', 'estimateKey', estimateKey);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('updateEstimate', 'version', version);
      const localVarPath = `/api/v1/guesstimator/estimates/{estimateKey};{version}`
        .replace(`{${'estimateKey'}}`, encodeURIComponent(String(estimateKey)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(immutableEstimate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuesstimatorApi - functional programming interface
 * @export
 */
export const GuesstimatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuesstimatorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Creates a new estimate
     * @param {CreateEstimateRequest} [createEstimateRequest] The request body with information needed to create a new estimate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEstimate(
      createEstimateRequest?: CreateEstimateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimate(createEstimateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a new task template
     * @param {CreateTaskTemplateRequest} [createTaskTemplateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTaskTemplate(
      createTaskTemplateRequest?: CreateTaskTemplateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskTemplate(createTaskTemplateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a new template group
     * @param {CreateTemplateGroupRequest} [createTemplateGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTemplateGroup(
      createTemplateGroupRequest?: CreateTemplateGroupRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateGroup(
        createTemplateGroupRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets a single estimate\'s data
     * @param {string} estimateKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstimate(
      estimateKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimate(estimateKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Metadata: Gets a list of all task templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskTemplates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskTemplateResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskTemplates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Metadata: Gets a list of all template groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTemplateGroups(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateGroupResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateGroups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Searches estimates and returns the common metadata for them
     * @param {string} [name]
     * @param {number} [skip] The number of chunks (or takes) to skip before returning the next chunk. Default is 0
     * @param {number} [take] The number of estimates to return in a chunk. Default is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchEstimates(
      name?: string,
      skip?: number,
      take?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateMetaDataSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchEstimates(name, skip, take, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates an estimate
     * @param {string} estimateKey The unique identifier for a specific estimate
     * @param {number} version The estimate related to a given version of an estimate
     * @param {ImmutableEstimate} [immutableEstimate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEstimate(
      estimateKey: string,
      version: number,
      immutableEstimate?: ImmutableEstimate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimate(
        estimateKey,
        version,
        immutableEstimate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GuesstimatorApi - factory interface
 * @export
 */
export const GuesstimatorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GuesstimatorApiFp(configuration);
  return {
    /**
     *
     * @summary Creates a new estimate
     * @param {CreateEstimateRequest} [createEstimateRequest] The request body with information needed to create a new estimate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEstimate(createEstimateRequest?: CreateEstimateRequest, options?: any): AxiosPromise<ModelUpdateIdentifier> {
      return localVarFp.createEstimate(createEstimateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new task template
     * @param {CreateTaskTemplateRequest} [createTaskTemplateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTaskTemplate(
      createTaskTemplateRequest?: CreateTaskTemplateRequest,
      options?: any
    ): AxiosPromise<ModelUpdateIdentifier> {
      return localVarFp
        .createTaskTemplate(createTaskTemplateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new template group
     * @param {CreateTemplateGroupRequest} [createTemplateGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTemplateGroup(
      createTemplateGroupRequest?: CreateTemplateGroupRequest,
      options?: any
    ): AxiosPromise<ModelUpdateIdentifier> {
      return localVarFp
        .createTemplateGroup(createTemplateGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gets a single estimate\'s data
     * @param {string} estimateKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimate(estimateKey: string, options?: any): AxiosPromise<EstimateResponse> {
      return localVarFp.getEstimate(estimateKey, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Metadata: Gets a list of all task templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTemplates(options?: any): AxiosPromise<Array<TaskTemplateResponse>> {
      return localVarFp.getTaskTemplates(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Metadata: Gets a list of all template groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateGroups(options?: any): AxiosPromise<Array<TemplateGroupResponse>> {
      return localVarFp.getTemplateGroups(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Searches estimates and returns the common metadata for them
     * @param {string} [name]
     * @param {number} [skip] The number of chunks (or takes) to skip before returning the next chunk. Default is 0
     * @param {number} [take] The number of estimates to return in a chunk. Default is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchEstimates(
      name?: string,
      skip?: number,
      take?: number,
      options?: any
    ): AxiosPromise<EstimateMetaDataSearchResponse> {
      return localVarFp.searchEstimates(name, skip, take, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates an estimate
     * @param {string} estimateKey The unique identifier for a specific estimate
     * @param {number} version The estimate related to a given version of an estimate
     * @param {ImmutableEstimate} [immutableEstimate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEstimate(
      estimateKey: string,
      version: number,
      immutableEstimate?: ImmutableEstimate,
      options?: any
    ): AxiosPromise<ModelUpdateIdentifier> {
      return localVarFp
        .updateEstimate(estimateKey, version, immutableEstimate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createEstimate operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiCreateEstimateRequest
 */
export interface GuesstimatorApiCreateEstimateRequest {
  /**
   * The request body with information needed to create a new estimate
   * @type {CreateEstimateRequest}
   * @memberof GuesstimatorApiCreateEstimate
   */
  readonly createEstimateRequest?: CreateEstimateRequest;
}

/**
 * Request parameters for createTaskTemplate operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiCreateTaskTemplateRequest
 */
export interface GuesstimatorApiCreateTaskTemplateRequest {
  /**
   *
   * @type {CreateTaskTemplateRequest}
   * @memberof GuesstimatorApiCreateTaskTemplate
   */
  readonly createTaskTemplateRequest?: CreateTaskTemplateRequest;
}

/**
 * Request parameters for createTemplateGroup operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiCreateTemplateGroupRequest
 */
export interface GuesstimatorApiCreateTemplateGroupRequest {
  /**
   *
   * @type {CreateTemplateGroupRequest}
   * @memberof GuesstimatorApiCreateTemplateGroup
   */
  readonly createTemplateGroupRequest?: CreateTemplateGroupRequest;
}

/**
 * Request parameters for getEstimate operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiGetEstimateRequest
 */
export interface GuesstimatorApiGetEstimateRequest {
  /**
   *
   * @type {string}
   * @memberof GuesstimatorApiGetEstimate
   */
  readonly estimateKey: string;
}

/**
 * Request parameters for searchEstimates operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiSearchEstimatesRequest
 */
export interface GuesstimatorApiSearchEstimatesRequest {
  /**
   *
   * @type {string}
   * @memberof GuesstimatorApiSearchEstimates
   */
  readonly name?: string;

  /**
   * The number of chunks (or takes) to skip before returning the next chunk. Default is 0
   * @type {number}
   * @memberof GuesstimatorApiSearchEstimates
   */
  readonly skip?: number;

  /**
   * The number of estimates to return in a chunk. Default is 10.
   * @type {number}
   * @memberof GuesstimatorApiSearchEstimates
   */
  readonly take?: number;
}

/**
 * Request parameters for updateEstimate operation in GuesstimatorApi.
 * @export
 * @interface GuesstimatorApiUpdateEstimateRequest
 */
export interface GuesstimatorApiUpdateEstimateRequest {
  /**
   * The unique identifier for a specific estimate
   * @type {string}
   * @memberof GuesstimatorApiUpdateEstimate
   */
  readonly estimateKey: string;

  /**
   * The estimate related to a given version of an estimate
   * @type {number}
   * @memberof GuesstimatorApiUpdateEstimate
   */
  readonly version: number;

  /**
   *
   * @type {ImmutableEstimate}
   * @memberof GuesstimatorApiUpdateEstimate
   */
  readonly immutableEstimate?: ImmutableEstimate;
}

/**
 * GuesstimatorApi - object-oriented interface
 * @export
 * @class GuesstimatorApi
 * @extends {BaseAPI}
 */
export class GuesstimatorApi extends BaseAPI {
  /**
   *
   * @summary Creates a new estimate
   * @param {GuesstimatorApiCreateEstimateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public createEstimate(requestParameters: GuesstimatorApiCreateEstimateRequest = {}, options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .createEstimate(requestParameters.createEstimateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new task template
   * @param {GuesstimatorApiCreateTaskTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public createTaskTemplate(
    requestParameters: GuesstimatorApiCreateTaskTemplateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GuesstimatorApiFp(this.configuration)
      .createTaskTemplate(requestParameters.createTaskTemplateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new template group
   * @param {GuesstimatorApiCreateTemplateGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public createTemplateGroup(
    requestParameters: GuesstimatorApiCreateTemplateGroupRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GuesstimatorApiFp(this.configuration)
      .createTemplateGroup(requestParameters.createTemplateGroupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets a single estimate\'s data
   * @param {GuesstimatorApiGetEstimateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public getEstimate(requestParameters: GuesstimatorApiGetEstimateRequest, options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .getEstimate(requestParameters.estimateKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Metadata: Gets a list of all task templates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public getTaskTemplates(options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .getTaskTemplates(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Metadata: Gets a list of all template groups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public getTemplateGroups(options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .getTemplateGroups(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Searches estimates and returns the common metadata for them
   * @param {GuesstimatorApiSearchEstimatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public searchEstimates(requestParameters: GuesstimatorApiSearchEstimatesRequest = {}, options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .searchEstimates(requestParameters.name, requestParameters.skip, requestParameters.take, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates an estimate
   * @param {GuesstimatorApiUpdateEstimateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuesstimatorApi
   */
  public updateEstimate(requestParameters: GuesstimatorApiUpdateEstimateRequest, options?: AxiosRequestConfig) {
    return GuesstimatorApiFp(this.configuration)
      .updateEstimate(
        requestParameters.estimateKey,
        requestParameters.version,
        requestParameters.immutableEstimate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
