// Add new environment specific values to the appropriate .env files. The name must start with REACT_APP in order
// for it to get picked up and inlined here.
// https://vitejs.dev/guide/env-and-mode.html#env-variables
export const Config = {
  apiRoot: import.meta.env['REACT_APP_API_ROOT'],
  /**
   * Websocket root
   *
   * @remarks We have Azure Front-Door in deployed envs, it doesnt support websockets so we have to go straight to the app service
   * */
  wsRoot: import.meta.env['REACT_APP_WS_ROOT'],
  oauth: {
    clientId: import.meta.env['REACT_APP_OAUTH_CLIENT_ID'],
    redirectUri: import.meta.env['REACT_APP_OAUTH_REDIRECT_URI'],
    authority: import.meta.env['REACT_APP_OAUTH_AUTHORITY'],
    intranetAccessScope: import.meta.env['REACT_APP_OAUTH_SCOPE_ACCESS'],
  },
  helpUrl: 'https://viagiotech.atlassian.net/wiki/spaces/AI/pages/2544861203/Viagio+Guesstimator',
};
