import { Action, combineReducers, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { featureFlagData } from '~/features/feature-flags/featureFlagSlice';
import { authSlice } from '~/features/auth/authSlice';
import { snackbarSlice } from '~/features/snackbar/snackBarSlice';
import storage from 'redux-persist/lib/storage';
import { emptyGuesstimatorSlice } from '~/features/guesstimator/guesstimator-empty-slice';
import { emptySkillsMatrixSlice } from '~/features/skills-matrix/skills-matrix-empty-slice';

declare module 'redux' {
  interface Store {
    /** record of injected reducers */
    lazyLoadedReducers: Record<string, any>;

    /** method to inject a reducer */
    injectReducer: (key: string, asyncReducer: any) => void;

    /* a mechanism to lazy inject middleware */
    injectMiddleware: (name: string, middleware: Middleware) => void;
  }
}

const staticReducers = {
  [authSlice.name]: authSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [featureFlagData.reducerPath]: featureFlagData.reducer,
  [emptySkillsMatrixSlice.reducerPath]: emptySkillsMatrixSlice.reducer,
  [emptyGuesstimatorSlice.reducerPath]: emptyGuesstimatorSlice.reducer,
};

/** Map of inserted middlewares.
 *
 * Want to use a Map because HMR may try to re-add the same middleware to the pipeline. When
 * that happens, we'll replace the previous instance instead of duplicating it
 */
const injectedMiddlewares = new Map<string, ReturnType<Middleware>>();
const injectableMiddleware: Middleware<{}, any> = (_api) => (dispatch) => (action) => {
  injectedMiddlewares.forEach((middleware) => middleware(dispatch)(action));
  return dispatch(action);
};

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

// Configure the store
export default function configureInjectableStore() {
  const store = configureStore({
    reducer: persistReducer(rootPersistConfig, combineReducers(staticReducers)) as any,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {},
        },
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        injectableMiddleware,
        emptySkillsMatrixSlice.middleware,
        featureFlagData.middleware,
        emptyGuesstimatorSlice.middleware
      ),
  });

  setupListeners(store.dispatch);

  // Add a dictionary to keep track of the registered lazy reducers
  store.lazyLoadedReducers = {};

  /**
   * Adds a new reducer to the store. This is so we can lazily laod new reducers and don't have to include
   * all their code in the initial bundle
   * @param key the root key for the reducer
   * @param newReducer
   */
  store.injectReducer = (key, newReducer) => {
    store.lazyLoadedReducers[key] = newReducer;
    store.replaceReducer(
      persistReducer(
        {
          key: 'root',
          whitelist: [],
          storage,
        },
        createReducer(store.lazyLoadedReducers) as any
      ) as any
    );
  };

  /**
   * Allows us to lazily load middleware into the store
   * @param name - the name of the middleware, so it can be replaced on HMR
   * @param middleware - the middleware function to run
   */
  store.injectMiddleware = (name, middleware) => {
    injectedMiddlewares.set(name, middleware(store));
  };

  // Return the modified store
  return store;
}

function createReducer(asyncReducers: Record<string, any>) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export const store = configureInjectableStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
