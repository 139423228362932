import { AxiosError } from 'axios';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

// For lazy loading reasons we want to stub this in into the main ~/app/store.ts. This sets up some of the
// redux-toolkit  middleware, but lets you actually add the API endpoints later on demand.

export const emptyGuesstimatorSlice = createApi({
  tagTypes: ['Estimate', 'EstimatesMetaData', 'TemplateGroups', 'TaskTemplates'],
  reducerPath: 'guesstimatorApi',
  baseQuery: fakeBaseQuery<AxiosError | Error>(), // informs typescript what kind of errors our queryFn's might return
  endpoints: () => ({}),
});
