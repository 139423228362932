import { createAsyncThunk, AsyncThunk, AsyncThunkPayloadCreator, AsyncThunkOptions } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from './store';

/**
 * Type-level configuration for redux-thunk
 * This is where the types in 'thunkAPI',
 * the second argument passed to thunk functions, come from.
 */
export interface ThunkConfig {
  extra: ThunkExtra;
  state: RootState;
  dispatch: AppDispatch;
  // NOTE the following properties can also be specified:
  // rejectValue?: unknown;
  // serializedErrorType?: unknown;
  // pendingMeta?: unknown;
  // fulfilledMeta?: unknown;
  // rejectedMeta?: unknown;
}

/**
 * Extra argument passed to redux-thunk functions
 * Used for mocking in tests
 */
export interface ThunkExtra {}

/**
 * A version of createAsyncThunk with app-specific configuration & types
 */
export function createAppThunk<Returned, ThunkArg = void, Config extends ThunkConfig = ThunkConfig>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, Config>,
  options?: AsyncThunkOptions<ThunkArg, Config>
): AsyncThunk<Returned, ThunkArg, Config> {
  return createAsyncThunk<Returned, ThunkArg, Config>(typePrefix, payloadCreator, options);
}
