import React, { FC, PropsWithChildren, ReactNode, useContext } from 'react';
import { FeatureFlagAccessorHook, FeatureFlagContextData } from './types';
import { useViagioAcceleratorFeatureFlags } from './viagio-accelerator-accessor';

const FeatureFlagContext = React.createContext<FeatureFlagContextData>({ isLoaded: false, featureFlags: {} });
export const FeatureFlagProvider = FeatureFlagContext.Provider;

// TODO: write your own hook that confirms to the FeatureFlagAccessorHook interface if you
// don't or can't use the default accelerator version
/** A hook to access feature flags for the app */
const useFeatureFlagAccessor: FeatureFlagAccessorHook = useViagioAcceleratorFeatureFlags;

/**
 * A component that automatically acquires current feature flags and provides
 * them to child components via React Context
 * @returns
 */
export const FeatureFlagAutoProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const contextValue = useFeatureFlagAccessor();
  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
};

/**
 * A hook to get access to the current feature flags
 */
export function useFeatureFlags() {
  return useContext(FeatureFlagContext);
}

/**
 * A hook to read the current value of a feature flag
 * @param featureName - the name of the feature to read
 * @param defaultValue  - (optional) a default value if the feature does not exist. Defaults to `false`
 * @returns value indicating if the flag is enabled
 */
export function useFeatureFlag(featureName: string, defaultValue = false) {
  return useContext(FeatureFlagContext).featureFlags[featureName] ?? defaultValue;
}

/**
 * A hook to determine if the feature flag set has been loaded (such as from an API)
 */
export function useFeatureFlagsLoaded() {
  const { isLoaded } = useContext(FeatureFlagContext);
  return isLoaded;
}

export type FeatureGateProps = PropsWithChildren<{
  /** the name of the feature */
  featureName: string;
  /** a default value for if the feature does not exist. Defaults to `false` */
  defaultValue?: boolean;
  /** fall back UI to render if the feature is disabled */
  fallback?: ReactNode;
}>;

/**
 * A component that only renders its `children` if the named feature flag is enabled
 */
export const FeatureGate: FC<FeatureGateProps> = ({ featureName, defaultValue, fallback, children }) => {
  const enabled = useFeatureFlag(featureName, defaultValue);
  if (!enabled) return fallback ? null : <>{fallback}</>;
  return <>{children}</>;
};
