export type LoadingState<T, E = string> =
  | { state: 'idle' }
  | { state: 'pending' }
  | { state: 'fulfilled'; value: T }
  | { state: 'rejected'; error: E };

function idle<T, E = string>(): LoadingState<T, E> {
  return { state: 'idle' };
}
function pending<T, E = string>(): LoadingState<T, E> {
  return { state: 'pending' };
}
function fulfilled<T, E = string>(value: T): LoadingState<T, E> {
  return { state: 'fulfilled', value };
}
function rejected<T, E = string>(error: E): LoadingState<T, E> {
  return { state: 'rejected', error };
}

export const LoadingStates = {
  map<TSource, TDestination>(
    source: LoadingState<TSource>,
    f: (x: TSource) => TDestination
  ): LoadingState<TDestination> {
    switch (source.state) {
      case 'idle':
      case 'pending':
      case 'rejected':
        return source;
      case 'fulfilled':
        return { state: 'fulfilled', value: f(source.value) };
    }
  },

  idle,
  pending,
  fulfilled,
  rejected,

  merge<T1, T2>(a: LoadingState<T1>, b: LoadingState<T2>): LoadingState<void> {
    if (a.state === 'rejected') return a;
    if (b.state === 'rejected') return b;
    if (a.state === 'pending' || b.state === 'pending') return pending();
    if (a.state === 'fulfilled' && b.state === 'fulfilled') return fulfilled(undefined);
    if (a.state === 'idle' && b.state === 'idle') return idle();
    return pending();
  },

  coalesce<T>(a: LoadingState<T>, defaultValue: T) {
    return a.state === 'fulfilled' ? a.value : defaultValue;
  },
};
