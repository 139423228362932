import { Outlet } from 'react-router-dom';
import { Header } from '~/app/Header';
import { SnackBarContainer } from '~/features/snackbar/SnackBarContainer';

export function MainLayout() {
  return (
    <>
      <Header />

      <Outlet />
      <SnackBarContainer />
    </>
  );
}
