import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { msalInstance } from '~/features/auth/authConfig';
import { FeatureFlagAutoProvider } from '~/features/feature-flags';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import theme from './theme';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <FeatureFlagAutoProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </ThemeProvider>
          </BrowserRouter>
        </FeatureFlagAutoProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
