import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { Config } from '~/app/config';

// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
export const msalConfig = {
  auth: {
    clientId: Config.oauth.clientId!,
    authority: Config.oauth.authority!,
    redirectUri: Config.oauth.redirectUri!,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //     logLevel: LogLevel.Verbose,
  //     loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //       }
  //     },
  //     piiLoggingEnabled: true,
  //   },
  // },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [Config.oauth.intranetAccessScope!],
  extraQueryParameters: {
    domain_hint: 'viagiotech.com',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
