import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Api } from '~/app/api';
import { WhoAmiResult } from '~/app/generated/api-client/v1';
import { LoadingStates } from '~/app/LoadingState';
import { createAppThunk } from '~/app/thunk';
import { featureFlagData } from '~/features/feature-flags/featureFlagSlice';
import { loginRequest, msalInstance } from './authConfig';

export const initialState = {
  user: LoadingStates.idle<WhoAmiResult>(),
};

type AuthState = typeof initialState;
type AuthSlice = { auth: AuthState };
const selectSlice = (state: AuthSlice) => state.auth;

export const selectUser = createSelector(selectSlice, (slice) => slice.user);

export const loginRequested = createAsyncThunk('auth/loginRequested', async () => {
  const authResult = await msalInstance.loginPopup(loginRequest);

  if (!authResult.account) throw new Error('Logged in successfully, but didnt get an account');

  msalInstance.setActiveAccount(authResult.account);

  return { account: authResult.account };
});

export const whoAmiRequested = createAppThunk<WhoAmiResult>(
  'auth/whoAmiRequested',
  async (_, api) => {
    const response = await Api.auth.whoAmI();

    // now that we're logged in, feature flag values might have changed if targeting is applied
    api.dispatch(
      featureFlagData.util.updateQueryData('getFeatureFlags', undefined, (draft) => {
        draft.featureFlags = response.data.featureFlags;
      })
    );
    return response.data;
  },
  {
    condition: (_, api) => {
      const user = selectUser(api.getState());
      return user.state === 'idle' || user.state === 'rejected';
    },
  }
);

export const logOutRequested = createAppThunk<void>('auth/logoutRequested', async () => {
  await msalInstance.logoutPopup({ account: msalInstance.getActiveAccount() });
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(whoAmiRequested.pending, (draft) => {
      draft.user = LoadingStates.pending();
    });

    builder.addCase(whoAmiRequested.rejected, (draft, action) => {
      draft.user = LoadingStates.rejected(action.error.message!);
    });

    builder.addCase(whoAmiRequested.fulfilled, (draft, action) => {
      draft.user = LoadingStates.fulfilled(action.payload);
    });

    builder.addMatcher(isAnyOf(logOutRequested.fulfilled, loginRequested.pending), (draft) => {
      draft.user = LoadingStates.idle();
    });
  },
});

export const selectWhoAmILoading = createSelector(selectUser, (u) => u.state === 'pending');
