import { useIsAuthenticated } from '@azure/msal-react';
import { Typography } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Permissions, WhoAmiResult } from '~/app/generated/api-client/v1';
import { useAppDispatch } from '~/app/hooks';
import { selectUser, whoAmiRequested } from './authSlice';

export const RequireAuth = () => {
  const isAuthenticated = useIsAuthenticated();
  let location = useLocation();
  const user = useSelector(selectUser);

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log('auth required, redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user.state === 'fulfilled') return <Outlet />;
  if (user.state === 'rejected') return <>{user.error}</>;
  return <>Logging you in...</>;
};

export type AuthGuardProps = {
  render: (user: WhoAmiResult) => ReactNode;
};
export const AuthGuard = (props: AuthGuardProps) => {
  const isAuthenticated = useIsAuthenticated();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(whoAmiRequested());
    }
  }, [dispatch, isAuthenticated]);

  if (user.state === 'fulfilled') return <>{props.render(user.value)}</>;
  return null;
};

export type PermissionPolicy = (user: WhoAmiResult) => boolean;

export type PermissionGuardProps = PropsWithChildren<{
  permission?: Permissions;
  policy?: PermissionPolicy;
}>;
export const PermissionGuard: FC<PermissionGuardProps> = ({ children, permission, policy }) => {
  if (!permission && !policy) throw new Error('Provide either permission or policy prop');

  const canAccess = policy ?? ((user) => user.permissions.includes(permission!));
  return <AuthGuard render={(user) => (canAccess(user) ? children : null)} />;
};

export const PermissionDenied = () => {
  return <Typography>Permission denied</Typography>;
};

export const PagePermissionGuard: FC<PermissionGuardProps> = ({ children, permission, policy }) => {
  if (!permission && !policy) throw new Error('Provide either permission or policy prop');
  const canAccess = policy ?? ((user) => user.permissions.includes(permission!));
  return <AuthGuard render={(user) => (canAccess(user) ? children : <PermissionDenied />)} />;
};

export function withRequiredPagePermissions(permission: Permissions) {
  return function (Component: React.ComponentType) {
    return () => (
      <AuthGuard render={(user) => (user.permissions.includes(permission) ? <Component /> : <PermissionDenied />)} />
    );
  };
}
