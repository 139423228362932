import { Divider, Icon, IconButton, ListItemIcon, Menu, MenuItem, Skeleton, Stack, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import * as paths from '~/features/skills-matrix/skills-matrix-paths';
import { logOutRequested, selectWhoAmILoading } from '~/features/auth/authSlice';
import { AuthGuard, PermissionGuard } from '~/features/auth/WhoAmIGuard';
import { UserAvatar } from '~/molecules/UserAvatar';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logoViagio from '../img/logo-viagio.png';
import { Permissions, WhoAmiResult } from './generated/api-client/v1';
import { useAppDispatch } from './hooks';

type StyleCallback = { isActive: boolean };
export const Header = () => {
  const theme = useTheme();
  const activeStyle = ({ isActive }: StyleCallback) =>
    isActive
      ? {
          color: theme.palette.primary.main,
          borderBottom: '2px solid ' + theme.palette.primary.main,
          fontWeight: 'bold',
        }
      : {};

  const loggingIn = useSelector(selectWhoAmILoading);

  return (
    <Wrapper>
      <Nav>
        <Link to="/" data-qa="link_home_header">
          <Logo src={logoViagio} alt="Intranet Logo" />
        </Link>
        <PermissionGuard permission={Permissions.ReadSkillReports}>
          <HeaderNavLink to="/sm/skills" style={activeStyle} data-qa="link_skills_header">
            Skills
          </HeaderNavLink>
          <HeaderNavLink to="/sm/roster" style={activeStyle} data-qa="link_roster_header">
            Roster
          </HeaderNavLink>
          <HeaderNavLink to="/guesstimator" style={activeStyle} data-qa="link_guesstimator_header">
            Guesstimator
          </HeaderNavLink>
          <HeaderNavLink to="/guesstimatorTemplates" style={activeStyle} data-qa="link_guesstimator_templates_header">
            Guesstimator Templates
          </HeaderNavLink>
        </PermissionGuard>
        <NavEndContainer>
          <AuthGuard render={(user) => <ProfileMenu user={user} />} />
          {loggingIn && <ProfileMenuSkeleton />}
        </NavEndContainer>
      </Nav>
    </Wrapper>
  );
};

function ProfileMenuSkeleton() {
  return (
    <>
      <Stack direction="row">
        <Skeleton variant="circular" height={40} width={40} />
      </Stack>
    </>
  );
}

type ProfileMenuProps = {
  user: WhoAmiResult;
};
const ProfileMenu = ({ user }: ProfileMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    await dispatch(logOutRequested());
    navigate('/');
  };

  const onProfileClick = () => {
    navigate(paths.profile({ slug: user.slug }));
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          data-qa="button_show-menu_profile-menu"
        >
          <UserAvatar user={user} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onProfileClick} data-qa="link_profile_profile-menu">
          <ProfileMenuItemContainer>
            <UserAvatar user={user} />
            <Stack>
              <div className="name">{user.name}</div>
              <div className="title">{user.jobPosition}</div>
            </Stack>
          </ProfileMenuItemContainer>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOut} data-qa="link_logout_profile-menu">
          <ListItemIcon>
            <Icon>logout</Icon>
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

const ProfileMenuItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),

  '& .name': {
    ...theme.typography.body1,
  },

  '& .title': {
    ...theme.typography.body2,
  },

  '& .MuiAvatar-root': {
    marginRight: theme.spacing(1),
  },
}));

const HeaderNavLink = styled(NavLink)(({ theme }) => ({
  padding: theme.spacing(2),
  textDecoration: 'none',
  color: theme.palette.ultraDarkGray,
}));

const Logo = styled('img')(({ theme }) => ({
  width: theme.spacing(3),
  marginRight: theme.spacing(1),
}));

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0 auto',
}));

const NavEndContainer = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15)',
  top: 0,
  position: 'sticky',
  zIndex: 1200,
  backgroundColor: theme.palette.background.default,
}));
