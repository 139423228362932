/* tslint:disable */
/* eslint-disable */
/**
 * ATG.Intranet.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Permissions = {
    ReadMetadata: 'ReadMetadata',
    WriteSkills: 'WriteSkills',
    ReadSkillReports: 'ReadSkillReports',
    ManageSkillAssignments: 'ManageSkillAssignments'
} as const;

export type Permissions = typeof Permissions[keyof typeof Permissions];



